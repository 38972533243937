// TEAM
const david = {
	href: "https://www.linkedin.com/in/davidacasey/",
	name: "David Casey",
	title: "Co-Founder & CEO",
	pngSrcName: "team/_DAVID_ceimua.png",
	webpSrcName: "team/_DAVID_aoyhis.webp",
	altText: "david casey headshot",
}
const ashley = {
	href: "https://www.linkedin.com/in/ashleybtaylor11/",
	name: "Ashley Taylor",
	title: "Co-Founder & COO",
	pngSrcName: "team/_ASHLEY_bpbefd.png",
	webpSrcName: "team/_ASHLEY_jjjs22.webp",
	altText: "ashley taylor headshot",
}
const sayer = {
	href: "https://www.linkedin.com/in/sayertindall/",
	name: "Sayer Tindall",
	title: "Director of Engineering",
	pngSrcName: "team/_SAYER_mvt6od.png",
	webpSrcName: "team/_SAYER_g3s2f4.webp",
	altText: "sayer tindall headshot",
}
const joaquin = {
	href: "https://www.linkedin.com/in/joaquinmorenoantuña/",
	name: "Joaquin Moreno",
	title: "Head of Accounting & Finance",
	pngSrcName: "team/_JOAQUIN_gto6pk.png",
	webpSrcName: "team/_JOAQUIN_pl0nzw.webp",
	altText: "joaquin moreno headshot headshot",
}
const bridger = {
	href: "https://www.linkedin.com/in/bridger-zoske/",
	name: "Bridger Zoske",
	title: "Head of Blockchain",
	pngSrcName: "team/_BRIDGER_xzfjsl.png",
	webpSrcName: "team/_BRIDGER_crikvr.webp",
	altText: "bridger zoske headshot",
}
const julian = {
	href: "https://www.linkedin.com/in/julian-feder-34497a112/",
	name: "Julian Feder",
	title: "Protocol Designer",
	pngSrcName: "team/_JULIAN_nvfb3z.png",
	webpSrcName: "team/_JULIAN_jdlinr.webp",
	altText: "julian feder headshot",
}
const joe = {
	href: "https://www.linkedin.com/in/josephsobrero/",
	name: "Joseph Sobrero",
	title: "Lead Designer",
	pngSrcName: "team/_JOE-1_jk6mne.png",
	webpSrcName: "team/_JOE_z5bekz.webp",
	altText: "joseph sobrero headshot",
}
const anna = {
	href: "https://www.linkedin.com/in/davidacasey/",
	name: "Anna Medina",
	title: "Head of Admin & HR",
	pngSrcName: "team/_ANNA_ncuxbz.png",
	webpSrcName: "team/_ANNA_fz5hfp.webp",
	altText: "anna medina headshot",
}
const nate = {
	href: "https://www.linkedin.com/in/nathaniel-furbeyre/",
	name: "Nate Furbeyre",
	title: "Full Stack Engineer",
	pngSrcName: "team/_NATE_knnkwa.png",
	webpSrcName: "team/_NATE_jpruah.webp",
	altText: "nate furbeyre headshot",
}
const rachel = {
	href: "https://www.linkedin.com/in/rachel-hinds-5a9a21161/",
	name: "Rachel Hinds",
	title: "Community & Market Development",
	pngSrcName: "team/_RACHEL_b4nuea.png",
	webpSrcName: "team/_RACHEL_kl3igb.webp",
	altText: "rachel hinds headshot",
}
const nathan = {
	href: "https://www.linkedin.com/in/natephillipsco/",
	name: "Nathan Phillips",
	title: "Head of Community & Market Development",
	pngSrcName: "team/_NATHAN_zb5vzq.png",
	webpSrcName: "team/_NATHAN_jm45f2.webp",
	altText: "nathan phillips headshot",
}
const alison = {
	href: "https://www.linkedin.com/in/alisonleasher/",
	name: "Alison Sher",
	title: "Communications & Media Manager",
	pngSrcName: "team/alison_ned4kz.png",
	webpSrcName: "team/alison_iasuxb.webp",
	altText: "alison sher headshot",
}
const asad = {
	href: "https://www.linkedin.com/in/asadzeeshan/",
	name: "Asad Zeeshan",
	title: "Head of Protocol Marketing",
	pngSrcName: "team/asad_cgv7lt.png",
	webpSrcName: "team/asad_aukflv.webp",
	altText: "Asad Zeeshan headshot"
}
const anisa = {
	href: "https://www.linkedin.com/in/anisagillfillan/",
	name: "Anisa Gillfillan",
	title: "Community & Market Development",
	pngSrcName: "team/anisa_yiq5ev.png",
	webpSrcName: "team/anisa_undinp.webp",
	altText: "Anisa Gillfillan headshot"
}
const gigi = {
	href: "https://www.linkedin.com/in/gigidouglas/",
	name: "Gigi Douglas",
	title: "Director of Network Marketing",
	pngSrcName: "team/gigi-sm_omra7c.png",
	webpSrcName: "team/gigi_ps8gxu.webp",
	altText: "Gigi Douglas headshot"
}
const mark = {
	href: 'https://www.linkedin.com/in/mark-joseph-santos-8533b551/',
	name: 'Mark Joseph Santos',
	title: 'Member Support',
	pngSrcName: 'team/mark_cygjqw.png',
	webpSrcName: 'team/mark_y02r4g.webp',
	altText: 'Mark Joseph Santos headshot'
}

// ADVISORS
const bill = {
	href: 'https://www.linkedin.com/in/bimelton/',
	name: 'Bill Melton',
	title: 'Founder of Verifone',
	pngSrcName: 'advisors/bill_tmfece.png',
	webpSrcName: '',
	altText: 'Bill Melton headshot'
}
const andrii = {
	href: 'https://www.linkedin.com/in/nixoid/',
	name: 'Andrii Zamovsky',
	title: 'Founder of Ambisafe',
	pngSrcName: 'advisors/andrii_ufgkes.png',
	webpSrcName: '',
	altText: 'Andrii Zamovsky headshot'
}
const luis = {
	href: 'https://www.linkedin.com/in/luisbebop/',
	name: 'Luis Silva',
	title: 'Founder of Cloudwalk',
	pngSrcName: 'advisors/luis_pae7al.png',
	webpSrcName: '',
	altText: 'Luis Silva headshot'
}
const ryan = {
	href: 'https://www.linkedin.com/in/ryan-berkun-47a61a10b/',
	name: 'Ryan Berkun',
	title: 'Founder of Teller Finance',
	pngSrcName: 'advisors/ryan_g7e02x.png',
	webpSrcName: '',
	altText: 'Ryan Berkun headshot'
}
const jim = {
	href: 'https://www.linkedin.com/in/jim-aviles-64123695/',
	name: 'Jim Aviles',
	title: 'Founder of Avigo, Advisor at Bitpay',
	pngSrcName: 'advisors/jim_ekmt6h.png',
	webpSrcName: '',
	altText: 'Jim Aviles headshot'
}
const galia = {
	href: 'https://www.linkedin.com/in/galia-benartzi-1a0aa220/',
	name: 'Galia Benartzi',
	title: 'Founder of Bancor',
	pngSrcName: 'advisors/galia_wa0wl8.png',
	webpSrcName: '',
	altText: 'Galia Benartzi headshot'
}
const abdul = {
	href: 'https://www.linkedin.com/in/abdulmajid54',
	name: 'Abdul Majid',
	title: 'Protocol Community Manager',
	pngSrcName: 'team/abdul-majid_zuhv34.png',
	webpSrcName: 'team/abdul-majid_qlxmal.webp',
	altText: 'Abdul Majid headshot'
}
const duke = {
	href: "https://www.linkedin.com/in/dukejones/",
	name: "Duke Jones",
	title: "Co-Founder of ReSource",
	pngSrcName: "team/_DUKE_qjyj20.png",
	webpSrcName: "team/_DUKE_h1jzs4.webp",
	altText: "duke jones headshot",
}

export const team = {
	founders: [david, ashley],
	'administration & finance': [joaquin, anna],
	'product & design': [joe],
	engineering: [sayer, bridger, nate],
	protocol: [asad, julian, abdul],
	'network & community': [gigi, nathan, alison, anisa, rachel, mark],
}

export const advisors = [
	bill,
	andrii,
	luis,
	ryan,
	jim,
	galia,
	duke,
]
