import {extendTheme} from "@chakra-ui/react";

const customTheme = extendTheme ({
    config: {
        cssVarPrefix: 'ck',
        initialColorMode: 'dark',
        useSystemColorMode: true,
    },
    colors: {
        none: 'rgba(0, 0, 0, 0)',
        primary: 'rgba(149, 127, 239, 1)',
        primaryActive: 'rgba(113, 97, 239, 1)',
        primaryVariant: 'rgba(183, 156, 237, 1)',
        primaryBg: 'rgba(149, 127, 239, 0.1)',
        secondary: 'rgba(245, 198, 117, 1)',
        secondaryActive: 'rgba(244, 158, 76, 1)',
        secondaryVariant: 'rgba(245, 238, 158, 1)',
        gray: {
            50: 'rgb(243, 242, 247)',
            100: 'rgb(229, 229, 234)',
            200: 'rgb(209, 209, 214)',
            300: 'rgb(199, 199, 204)',
            400: 'rgb(174, 174, 178)',
            450: 'rgb(142, 142, 147)',
            500: 'rgb(99, 99, 102)',
            600: 'rgb(72, 72, 74)',
            700: 'rgb(58, 58, 60)',
            800: 'rgb(44, 44, 46)',
            900: 'rgb(28, 28, 30)'
        }
    },
    fonts: {
        title: 'Poppins, -apple-system, BlinkMacSystemFont, sans-serif',
        heading: 'Poppins, -apple-system-headline, -apple-system, BlinkMacSystemFont, sans-serif',
        subheading: 'Poppins, -apple-system-subheadline, -apple-system, BlinkMacSystemFont, sans-serif',
        body: 'Poppins, -apple-system-body, -apple-system, BlinkMacSystemFont, sans-serif',
        caption: 'Poppins, -apple-system-caption, -apple-system, BlinkMacSystemFont, sans-serif',
        mono: 'SFMono-Regular, Monaco, Menlo, "Courier New", monospace'
    },
    fontSizes: {
        xs: "0.75rem",
        sm: "0.875rem",
        md: "1rem",
        lg: "1.125rem",
        xl: "1.25rem",
        "2xl": "1.5rem",
        "3xl": "1.875rem",
        "4xl": "2.25rem",
        "5xl": "3rem",
        "6xl": "3.75rem",
        "7xl": "4.5rem",
        "8xl": "6rem",
        "9xl": "8rem",
    },
    fontWeights: {
        hairline: 100,
        thin: 200,
        light: 300,
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        extrabold: 800,
        black: 900,
    },
    lineHeights: {
        normal: "normal",
        none: 1,
        shorter: 1.25,
        short: 1.375,
        base: 1.5,
        tall: 1.625,
        taller: "2",
        "3": ".75rem",
        "4": "1rem",
        "5": "1.25rem",
        "6": "1.5rem",
        "7": "1.75rem",
        "8": "2rem",
        "9": "2.25rem",
        "10": "2.5rem",
    },
    letterSpacings: {
        tighter: "-0.05em",
        tight: "-0.025em",
        normal: "0",
        wide: "0.025em",
        wider: "0.05em",
        widest: "0.1em",
    },
    space: {
        px: '1px',
        0.5: '0.125rem',
        1: '0.25rem',
        1.5: '0.375rem',
        2: '0.5rem',
        2.5: '0.625rem',
        3: '0.75rem',
        3.5: '0.875rem',
        4: '1rem',
        5: '1.25rem',
        6: '1.5rem',
        7: '1.75rem',
        8: '2rem',
        9: '2.25rem',
        10: '2.5rem',
        12: '3rem',
        14: '3.5rem',
        16: '4rem',
        20: '5rem',
        24: '6rem',
        28: '7rem',
        32: '8rem',
        36: '9rem',
        40: '10rem',
        44: '11rem',
        48: '12rem',
        52: '13rem',
        56: '14rem',
        60: '15rem',
        64: '16rem',
        72: '18rem',
        80: '20rem',
        96: '24rem',
    },
    sizes: {
        max: 'max-content',
        min: 'min-content',
        full: '100%',
        '3xs': '14rem',
        '2xs': '16rem',
        xs: '20rem',
        sm: '24rem',
        md: '28rem',
        lg: '32rem',
        xl: '36rem',
        '2xl': '42rem',
        '3xl': '48rem',
        '4xl': '56rem',
        '5xl': '64rem',
        '6xl': '72rem',
        '7xl': '80rem',
        '8xl': '90rem',
        container: {
            sm: '640px',
            md: '768px',
            lg: '1024px',
            xl: '1280px',
        },
    },
    radii: {
        none: '0',
        sm: '0.125rem',
        base: '0.25rem',
        md: '0.375rem',
        lg: '0.5rem',
        xl: '0.75rem',
        '2xl': '1rem',
        '3xl': '1.5rem',
        full: '9999px',
    },
    zIndices: {
        hide: -1,
        auto: 'auto',
        base: 0,
        docked: 10,
        dropdown: 1000,
        sticky: 1100,
        banner: 1200,
        overlay: 1300,
        modal: 1400,
        popover: 1500,
        skipLink: 1600,
        toast: 1700,
        tooltip: 1800,
    },
})

export default customTheme;
