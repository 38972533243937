import React from 'react'
import { Box, Flex, Heading, Link, Text, useColorMode } from '@chakra-ui/react'
import { getCloudinaryImagePath } from './App'

const whatIsMutualCredit = {
	title: 'What is Mutual Credit?',
	description: 'Mutual Credit shouldn’t be confused with Mutual Banking, Credit Unions, or Cooperative Banking.',
	imgSrcName: 'blog/together_iqhx1a.jpg',
	href: 'https://www.blog.resource.finance/chapter-1-what-is-mutual-credit',
	altText: 'together we have force type'
}
const decentralizedUnderwriting = {
	title: 'Decentralized Underwriting',
	description: 'An autonomous, distributed DLT-protocol that holds enormous potential for making economic prosperity accessible to all.',
	imgSrcName: 'blog/decentralized_xqxjjn.jpg',
	href: 'https://www.blog.resource.finance/chapter-3--decentralised-underwriting',
	altText: '3d blockchain illustration'
}
const whyWeBuilt = {
	imgSrcName: 'blog/drawing_kurraa.jpg',
	title: 'Why we have built the ReSource Protocol',
	description: 'If Mutual Credit systems are so consumer friendly, why haven’t they already out-competed established credit providers?',
	href: 'https://www.blog.resource.finance/chapter-2-why-we-have-built-the-resource-protocol',
	altText: 'person sketching'
}
const blogs = [ whatIsMutualCredit, whyWeBuilt, decentralizedUnderwriting ]


export const BlogsPreview = () => {

	const { colorMode } = useColorMode()
	const textColor = colorMode === 'light' ? 'black' : 'white'

	const renderBlogLink = blog => { return (
		<Link
			key = { blog.altText }
			href = { blog.href }
			isExternal
			direction = 'column'
			align = 'flex-start'
			justify = 'flex-start'
			w = { ['full', '33%'] }
			p = { [4, 4, 6, 8] }
		>
			<Box
				w = '100%'
				pt = '60%'
				background = { `center center no-repeat url(${getCloudinaryImagePath(blog.imgSrcName)})` }
				backgroundSize = 'cover'
				borderRadius = '16px'
			/>
			<Heading
				fontSize = { ['xs', 'sm', 'md', 'lg'] }
				fontWeight = 'semibold'
				color = { textColor }
				mt = { [2, 2, 3, 4] }
				mb = { [1, 1, 2] }
			>
				{ blog.title }
			</Heading>
			<Text
				fontSize = { ['xs', 'sm', 'md', 'lg'] }
				color = { textColor }
				opacity = '0.8'
			>
				{ blog.description }
			</Text>
		</Link>
	) }

	return (
		<Flex
			direction = 'column'
			mx = { [8, 8, 12, 16] }
			my = { [16, 16, 24, 32] }
			align = 'center'
			justify = 'center'
		>
			<Heading
				textAlign = 'center'
				fontSize = { ['lg', 'xl', '2xl', '3xl'] }
				color = { textColor }
				mb = { 2 }
			>
				Recent blogs
			</Heading>
			<Flex
				direction = { ['column', 'row'] }
				align = 'flex-start'
				justify = 'space-between'
				w = 'full'
			>
				{ blogs.map( blog =>
					renderBlogLink(blog)
				) }
			</Flex>
		</Flex>
	)
}

export default BlogsPreview