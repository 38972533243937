import React, { useState } from 'react'
import { Link, Button, Flex, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, useColorMode } from "@chakra-ui/react"
import { getCloudinaryImagePath } from "./App"
import { featuredSocialLinks } from "../data/socialLinks"
import { useNavigate } from 'react-router-dom';
import { navLinks } from '../data/links'

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSun, faMoon, faBars, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

// images
const logotypeWhiteSrcName = "/brand/resource-logotype-white_osat1c.svg"
const logotypeBlackSrcName = "/brand/resource-logotype-black_lsczap.svg"


const usePathname = () => {
  const paths = window.location.pathname.split("/");
  return paths[1]
}

export const Nav = props => {
  // state
  const [ selected, setSelected ] = useState(usePathname())
  const { colorMode, toggleColorMode } = useColorMode()
  const navigate = useNavigate()

  // color mode helpers
  const colorModeIcon = colorMode === "light" ? faSun : faMoon
  const colorModeIconColor = colorMode === "light" ? "blue" : "yellow"
  const textColor = colorMode === "light" ? "black" : "white"
  const logotypeSrcName = colorMode === "light" ? logotypeBlackSrcName : logotypeWhiteSrcName
  // const menuGroupTitleColor = colorMode === "light" ? "rgba(0, 0, 0, 0.33)" : "rgba(255, 255, 255, 0.33)"

	const handleClick = (e, href) => {
		e.preventDefault()
    const formatted = href.split('/')
    setSelected(formatted[1])
		navigate(href)
	}

  const handleClickNav = () => {
    setSelected('')
    navigate('')
  }

  const renderLogotype = () => {
    return <Image w={"auto"} src={getCloudinaryImagePath(logotypeSrcName)} alt={"resource logotype"} />
  }

  const renderMenuItem = (menuItem) => {
    return menuItem.icon ? (
      <MenuItem
        icon = { <FontAwesomeIcon icon={menuItem.icon} size={"lg"} fixedWidth style={{height:'24px', paddingTop: '2px'}} /> }
        as = { Link }
				key = { menuItem.title }
				href={ menuItem.isExternal ? menuItem.href : '#' }
				onClick = { menuItem.isExternal ? '' : (event) => handleClick(event, menuItem.href)}
				outline = "none"
        color = {
          menuItem.title.toLowerCase() === selected.toLowerCase()
            ? 'primaryActive'
            : textColor
        }
        boxShadow = "none"
        isExternal = { menuItem.isExternal }
        _active = {{
          outline: "none",
          boxShadow: "none",
        }}
        _hover={{
          outline: "none",
          boxShadow: "none",
          textDecoration: "none",
          color: menuItem.brandColor,
        }}
        _focus={{
          outline: "none",
          boxShadow: "none",
        }}
      >
        {menuItem.title}
      </MenuItem>
    ) : (
      <MenuItem
        as = { Link }
        key = { menuItem.title }
				href = { menuItem.isExternal ? menuItem.href : '#' }
				onClick = { menuItem.isExternal ? '' : (event) => handleClick(event, menuItem.href) }
        outline = 'none'
        textDecoration = 'none'
        color = {
          menuItem.title.toLowerCase() === selected.toLowerCase()
            ? 'primaryActive'
            : textColor
        }
        boxShadow = 'none'
        isExternal = { menuItem.isExternal }
        _active = {{
          outline: "none",
          boxShadow: "none",
        }}
        _hover = {{
          outline: "none",
          boxShadow: "none",
          textDecoration: 'none',
          color: "primaryActive",
        }}
        _focus = {{
          outline: "none",
          boxShadow: "none",
        }}
      >
        { menuItem.title }
        { menuItem.isExternal ?
          <FontAwesomeIcon
            icon = { faExternalLinkAlt }
            size = { 'xs' }
            style = {{
              opacity: '0.5',
              marginLeft: 8
            }}
          />
          :
          ''
        }
      </MenuItem>
    )
  }

  const renderButton = (button) => {
    return (
      <Button
        as = { Link }
        href = { button.isExternal ? button.href : '#' }
				onClick = { button.isExternal ? '' : (event) => handleClick(event, button.href)}
        isExternal = { button.isExternal }
        rightIcon = {
          button.isExternal ?
            <FontAwesomeIcon icon = { faExternalLinkAlt } size={ 'xs' } style = {{ opacity: '0.5' }} />
            :
            ''
        }
        outline = 'none'
        color = {
          button.title.toLowerCase() === selected.toLowerCase()
            ? 'primaryActive'
            : textColor
        }
        bgColor = { 'none' }
        _hover = {{
          color: 'primaryActive',
          textDecoration: 'none'
        }}
        _focus = {{
          bgColor: 'none',
          color: 'primaryActive',
          textDecoration: 'none',
          boxShadow: 'none',
        }}
        _active={{
          bgColor: 'none',
          color: 'primaryActive',
          textDecoration: 'none',
        }}
      >
        {button.title}
      </Button>
    )
  }

	const renderIconButton = (button) => {
		return (
			<IconButton
				key = { button.title }
				as={Link}
				href={button.href}
				icon={<FontAwesomeIcon icon={button.icon} size={"lg"} />}
				aria-label={button.ariaLabel}
				bgColor="none"
				mx={1}
				isExternal
				_hover = {{
					color: button.brandColor,
				}}
        _focus = {{
          boxShadow: 'none'
        }}
			/>
		)
	}

  const renderColorModeButton = () => {
    return (
      <Button
        onClick={toggleColorMode}
        color={colorModeIconColor}
        bgColor="primaryBg"
        ml={4}
        _hover = {{
          color: textColor,
          bgColor: "primaryBg",
        }}
        _focus = {{
          boxShadow: 'none'
        }}
        _after = {{
          outline: "none",
        }}
      >
        <FontAwesomeIcon icon={colorModeIcon} size={"1x"} />
      </Button>
    )
  }

  const renderDropdownMenu = () => {
    return (
      <Menu>
        <MenuButton
          color={textColor}
          as={Button}
          rightIcon={<FontAwesomeIcon icon={faBars} size={"lg"} />}
          bgColor={"none"}
          _hover={{
            color: "primaryActive",
            bgColor: "none",
            outline: "none",
            boxShadow: "none",
          }}
          _active={{
            color: "primaryActive",
            outline: "none",
            boxShadow: "none",
            bgColor: "none",
          }}
          _focus={{
            color: textColor,
            outline: "none",
            boxShadow: "none",
            bgColor: "none",
          }}
          _expanded={{
            color: "primaryActive",
            outline: "none",
            boxShadow: "none",
            bgColor: "none",
          }}
        />

        <MenuList
          bgColor="primaryBg"
          m={2}
          ml={-2}
          p={4}
          border="none"
          outline="none"
          borderTop="1px solid #7161EF"
          borderRadius="0"
          boxShadow="none"
          backdropFilter={"blur(10px)"}
        >
          { navLinks.map( link =>
            renderMenuItem( link )
          ) }
        </MenuList>
      </Menu>
    )
  }

  return (
    <Flex
      direction = 'row'
      wrap = 'nowrap'
      alignItems="center"
      justifyContent="space-between"
      w="full"
      align="center"
      bgColor="primaryBg"
      p = { 4 }
      px = { [8, 8, 12, 16] }
      zIndex = { 100 }
    >
      <Link w={"128px"} onClick = {handleClickNav} >
        {renderLogotype()}
      </Link>
      <Flex display={["none", "none", "none", "flex"]}>
        { navLinks.map(link =>
          renderButton(link)
        ) }
      </Flex>
      <Flex display={["none", "none", "none", "flex"]} direction={"row"} justify={"flex-end"}>
        {featuredSocialLinks.map((link) => renderIconButton(link))}
        {renderColorModeButton()}
      </Flex>
      <Flex display={["flex", "flex", "flex", "none"]} direction={"row"} justify={"flex-end"}>
        {renderDropdownMenu()}
        {renderColorModeButton()}
      </Flex>
    </Flex>
  )
}

export default Nav
