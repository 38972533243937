// imports
import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"

// components
import Home from "./Home"
import Brand from "./Brand";

// styles
import "./Styles.css"
import News, { Media } from './Media'
import Careers from "./Careers";
import About from './About';
import Faq from './Faq'
import Nav from './Nav'
import Footer from './Footer'

// cloudinary
export const getCloudinaryImagePath = (fileName) =>
	"https://res.cloudinary.com/resource-network/image/upload/v1639786610/resource-protocol/" + fileName


function App() {
	return (
		<BrowserRouter>
			<Nav />
			<Routes>
				<Route path={"/"} element={<Home />} />
				<Route path={"/brand"} element={<Brand />} />
				<Route path={"/careers"} element={<Careers/>} />
				<Route path={"/about"} element={<About/>} />
				<Route path={"/faq"} element={<Faq/>} />
				<Route path={"/media"} element={<Media/>} />
			</Routes>
			<Footer />
		</BrowserRouter>
	)
}

export default App
