const activism = {
	title: 'Together, we are champions of decentralized activism',
	description: 'The time has come for humanity to lead ourselves. We believe that decentralization (and the ' +
		'technologies that support it) hold the greatest power to liberate humanity from the power structures that ' +
		'control us. We created ReSource as a way to use blockchain technology to create real world, people-powered ' +
		'solutions to pressing global issues.',
	imgSrcName: 'about-icons/activism_haebyo.svg',
	darkImgSrcName: 'about-icons/activism-dark_vt5rn2.svg',
}
const community = {
	title: 'We believe that community is the real hero',
	description: 'Like Aristotle said, the whole is greater than the sum of its parts. Our protocol may provide the ' +
		'tools, but the strength of our economy is ultimately determined by all of you. Our alternative cryptocurrency ' +
		'was created with community in mind — to incentive trade amongst neighbors and circulate wealth inside of local ' +
		'economies. We are proud to be an open source technology, which means that our network can be shaped by ' +
		'programmers and ambassadors who want to expand upon and improve it.',
	imgSrcName: 'about-icons/community_wtcyhp.svg',
	darkImgSrcName: 'about-icons/community-dark_at2lpv.svg',
}
const challenge = {
	title: 'We turn challenge into beauty & resilience',
	description:
		'We are composters of civilization, transforming dying systems into the fuel for our vision of a regenerative ' +
		'economy that works for everyone. We arise out of “what is” and use every resource available to co-create a more ' +
		'beautiful world. We know that social change happens alongside the internal and strive to alchemize every ' +
		'challenge that arises into personal and collective growth.',
	imgSrcName: 'about-icons/challenge_xjowqd.svg',
	darkImgSrcName: 'about-icons/challenge-dark_yrem58.svg',
}
const trust = {
	title: 'We extend generosity & cultivate trust.',
	description:
		'We believe that the practice of giving out loans at unreasonable interest rates is one of the main ways our ' +
		'modern financial system works against the average person. That’s why ReSource offers alternative lines of ' +
		'credit (what we call Lines of Trust -or- overdraft protection) to every business who joins our network. This ' +
		'way you can have ReSource dollars to spend on what you need when you need it, as long as you pay back your ' +
		'negative balance by providing goods and services to network members later on. The integrity of our system is ' +
		'dependent on the integrity of the individuals inside of it.',
	imgSrcName: 'about-icons/giving_uwpaap.svg',
	darkImgSrcName: 'about-icons/giving-dark_jrpmzp.svg',
}
const empowerment = {
	title: 'We pursue individual empowerment to create collective abundance',
	description:
		'When people are given a chance to heal and utilize the life-giving resources that surround us, the end result ' +
		'is they are more creative. We believe that inside every person exists a spark of energy that desires to ' +
		'manifest its potential by sharing our unique gifts with others. We are here to provide access to organic food, ' +
		'health and wellness practitioners, and other vital resources to millions of people worldwide, so they can be ' +
		'their best selves. Help us usher in this new paradigm of regenerative finance that rewards and also inspires ' +
		'the ingenuity inherent to humankind. ',
	imgSrcName: 'about-icons/individual_vudhhb.svg',
	darkImgSrcName: 'about-icons/individual-dark_ikldde.svg',
}
const grateful = {
	title: 'We remember to stay grateful.',
	description:
		'Despite the crises present, we acknowledge the blessings made possible by industrialization and capitalism. To ' +
		'get entirely “off the machine” at this point in time would be impossible. It’s with this attitude of gratitude ' +
		'that we introduce our complementary economic system to the world to balance out the extractive methods that ' +
		'have made so many companies profitable. We don’t seek to destroy that which currently ensures our survival. We ' +
		'are here to evolve what’s been built before us — to fill in opportunity gaps, expand access to resources, and ' +
		'develop a new kind of money designed to liberate human potential.',
	imgSrcName: 'about-icons/grateful_z4ies6.svg',
	darkImgSrcName: 'about-icons/grateful-dark_xyeflu.svg',
}


export const values = [ activism, community, challenge, trust, empowerment, grateful ]
