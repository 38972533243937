import React from 'react'
import {Button, Flex, Heading, Link, Text, useColorMode} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

// data
let softwareDevelopment = {
  title: "Full Stack Software Engineer - Systems",
  href: "https://resource-network.notion.site/Full-Stack-Software-Engineer-Systems-b2f7ed14e22f4adca148c07b60649715",
  cta: "Interested in joining the ReSource tech team? Click below to read about the details of this role and apply."
}

let careers = [softwareDevelopment]


export const Careers = () => {
  const { colorMode } = useColorMode()
  const textColor = colorMode === 'light' ? 'black' : 'white'

  const renderCareers = () => {
    return (careers.map((career) =>
      <Flex
        key={career.title}
        direction = 'column'
        align = 'flex-start'
      >
        <Heading
          color = { textColor }
          fontSize = { ['lg', 'xl', '2xl', '3xl'] }
          fontWeight = 'semibold'
          mb = { [1, 1, 2, 2] }
        >
          {career.title}
        </Heading>
        <div className={"career-details "}>
          <Text
            fontSize = { ['sm', 'md', 'lg', 'xl'] }
            mb = { [4, 4, 6, 8] }
            color = { textColor }
          >
            {career.cta}
          </Text>
          <Button
            as = { Link }
            href = { career.href }
            rightIcon = { <FontAwesomeIcon icon = {faExternalLinkAlt} size={'sm'} />}
            isExternal
            bgColor = 'primary'
            color = 'white'
            cursor = 'pointer'
            textDecoration = 'none'
            _active = {{
              bgColor: 'primaryActive',
              textDecoration: 'none'
            }}
            _hover = {{
              bgColor: 'primaryActive',
              textDecoration: 'none'
            }}
            _focus = {{
              bgColor: 'primaryActive',
              textDecoration: 'none'
            }}
          >
            Job description
          </Button>
        </div>
      </Flex>
    ))
  }

  return (
    <Flex
      direction = 'column'
      w = 'full'
      justify = 'flex-start'
      align = 'center'
      minH = '100vh'
    >
      <Flex
        direction = 'column'
        alignSelf = 'center'
        justifySelf = 'center'
        align = 'center'
        justify = 'center'
        w = { ['96%', '90%', '86%', '80%']}
        maxW = { '800px' }
        m = { [16, 16, 24, 32] }
      >
        { renderCareers() }
      </Flex>
    </Flex>
  )
}

export default Careers;