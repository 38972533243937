const manfestoDefiForPeople = {
	title: 'A Manifesto for DeFi for the People',
	description: 'The Celo Foundation launched the $100M DeFi for the People (DFTP) initiative on August 30, 2021, to make ' +
		'DeFi accessible to the 6 billion mobile phone users around the world.',
	banner: 'articles/defi4peeps_uqbesg.jpg',
	author: 'Celo Foundation',
	authorImgSrcName: 'articles/celo_kbbuqy.png',
	href: 'https://medium.com/celoorg/a-manifesto-for-defi-for-the-people-4b84a912d5b3',
	alt: 'celo foundation logo',
	date: 'February 4, 2022',
}
const peopleFirstApproach = {
	title: '3 Ways You Can Implement A People-First Approach In Your Business',
	description: 'As we collectively navigate through the beginning of our third pandemic year, it is clear that there are ' +
		'many elements of business processes that could use some revamping',
	banner: 'articles/peepstable_zthtui.jpg',
	author: 'Forbes',
	authorImgSrcName: 'articles/forbes_z6pjv9.jpg',
	href: 'https://www.forbes.com/sites/stephanieburns/2022/01/07/3-ways-you-can-implement-a-people-first-approach-in-your-business/?sh=65e84e5b30ff',
	alt: 'forbes logo',
	date: 'January 7, 2022',
}
const offerUndercollateralizedCredit = {
	title: 'ReSource Finance Raises $1.7 Million to Offer Undercollateralized Credit to Web 3 Businesses',
	description: 'Decentralized credit protocol, ReSource Finance completed a $1.7 million funding round led by Future ' +
		'Perfect Ventures, NGC Ventures, and ExNetwork to enhance decentralized credit access to Web 3 businesses.',
	banner: 'articles/oldpeeps_wghk58.jpg',
	author: 'Investing.com',
	authorImgSrcName: 'articles/investing_ufh5w9.jpg',
	href: 'https://www.investing.com/news/cryptocurrency-news/resource-finance-raises-17-million-to-offer-undercollateralized-credit-to-web-3-businesses-2589192',
	alt: 'investing.com logo',
	date: 'August 13, 2021',
}
const bearMarketOver = {
	title: 'Recent Crypto And Blockchain Investments Confirm The Bear Market Is Over',
	description: 'Cryptocurrency and blockchain companies continue to attract many eyeballs in recent months. Although ' +
		'it seemed as if the industry was subject to another bear market recently, that sentiment has evaporated.',
	banner: 'articles/btc_i5qmen.jpg',
	author: 'Entrepreneur',
	authorImgSrcName: 'articles/entrepreneur_gtz0mq.jpg',
	href: 'https://www.entrepreneur.com/article/381673',
	alt: 'Entrepreneur logo',
	date: 'August 25, 2021',
}

export const articles = [
	manfestoDefiForPeople,
	peopleFirstApproach,
	bearMarketOver,
	offerUndercollateralizedCredit,
]