import React, { useState } from 'react'
import { Button, Flex, Heading, Link, Text, useColorMode } from '@chakra-ui/react'
import { marketplaceFaqs, protocolFaqs } from '../data/faqs'

export const Faq = () => {
	const { colorMode } = useColorMode()
	const textColor = colorMode === 'light' ? 'black' : 'white'
	const buttonBgColor = colorMode === 'light' ? 'gray.50' : 'gray.700'
	const [selected, setSelected] = useState('protocol')

	const handleClick = e => {
		e.preventDefault()
		setSelected(e.target.id)
	}

	const renderHeader = () => { return (
		<Flex
			direction = 'column'
			mb = { [8, 8, 12, 16] }
		>
			<Heading
				fontSize = { ['lg', 'xl', '2xl', '3xl'] }
				color = { textColor }
				mb = { [2, 2, 3, 4] }
			>
				Frequently asked questions
			</Heading>
			<Flex
				direction = 'row'
			>
				<Button
					id = 'protocol'
					onClick = { handleClick }
					bgColor = { selected === 'protocol' ? 'primary' : buttonBgColor }
					mr = '2'
				>
					Protocol
				</Button>
				<Button
					id = 'marketplace'
					bgColor = { selected === 'marketplace' ? 'primary' : buttonBgColor }
					onClick = { handleClick }
				>
					Marketplace
				</Button>
			</Flex>
		</Flex>
	) }

	const renderFaq = faq => { return (
		<Flex
			key = { faq.a.slice(0, 10) }
			direction = 'column'
			align = 'flex-start'
			justify = 'flex-start'
			mb = { [4, 4, 6, 8] }
		>
			<Heading
				fontSize = { ['xs', 'sm', 'md', 'lg'] }
				fontWeight = 'semibold'
				color = { textColor }
				mb = { [1, 1, 2] }
			>
				{ faq.q }
			</Heading>
			{ faq.a.map(answer =>
				answer.slice(0, 5) === 'https' ?
					<Link
						href = {answer}
						color = { 'primary' }
						_hover = {{ color: 'primaryActive' }}
						mb = { [2, 2, 3, 4] }
					>
						{ answer }
					</Link>
					:
					<Text
						fontSize = { ['xs', 'sm', 'md', 'lg'] }
						color = { textColor }
						opacity = '0.8'
						mb = { [1, 1, 2, 2] }
					>
						{ answer }
					</Text>
			)}
		</Flex>
	) }

	const renderFaqSection = () => {
		switch(selected) {
			case 'protocol':
				return protocolFaqs.map( faq => renderFaq(faq) )
			case 'marketplace':
				return marketplaceFaqs.map( faq => renderFaq(faq) )
			default:
				return protocolFaqs.map( faq => renderFaq(faq) )
		}
	}

	return (
		<Flex
			direction = 'column'
			minH = '100vh'
			align = 'center'
			justify = 'flex-start'
		>
			<Flex
				direction = 'column'
				m = { [8, 16, 24, 32] }
				maxW = '800px'
			>
				{ renderHeader() }
				{ renderFaqSection() }
			</Flex>
		</Flex>
	)
}

export default Faq