import React from 'react'
import { Box, Flex, Heading, Image, Link, Text, useColorMode } from '@chakra-ui/react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { articles } from '../data/articles'
import { getCloudinaryImagePath } from './App'

export const Media = () => {
	const { colorMode } = useColorMode()
	const textColor = colorMode === 'light' ? 'black' : 'white'
	const dateColor = colorMode === 'light' ? 'gray.500' : 'gray.400'
	const borderColor = colorMode === 'light' ? 'gray.100' : 'gray.600'

	const renderTitle = () => { return (
		<Heading
			color = { textColor }
			fontSize = { ['lg', 'xl', '2xl', '3xl'] }
			fontWeight = 'semibold'
			mb = { [8, 8, 12, 16] }
		>
			ReSource in the media
		</Heading>
	) }

	const renderBanner = article => { return (
		<Box
			pt = '33%'
			w = '100%'
			background = { `center center no-repeat url(${getCloudinaryImagePath(article.banner)})` }
			backgroundSize = 'cover'
			borderRadius = '8px'
			mb = { [2, 2, 3, 4] }
		/>
	) }

	const renderHeading = article => { return (
		<Heading
			color = { textColor }
			fontSize = { ['md', 'lg', 'xl', '2xl'] }
			fontWeight = 'semibold'
			mb = { [1, 1, 2, 2] }
		>
			{article.title}
		</Heading>
	) }

	const renderDescription = article => { return (
		<Text
			fontSize = { ['sm', 'md', 'lg', 'xl'] }
			color = { textColor }
		>
			{ article.description }
		</Text>
	) }

	const renderDate = article => { return (
		<Text
			color = { dateColor }
		>
			{ article.date }
		</Text>
	) }

	const renderLink = article => { return (
		<Link
			ml = { 4 }
			href = { article.href }
			isExternal
			color = 'primary'
			_active = {{
				color: 'primaryActive',
			}}
			_hover = {{
				color: 'primaryActive',
			}}
			_focus = {{
				color: 'primaryActive',
			}}
		>
			Read more
			<FontAwesomeIcon
				icon = { faExternalLinkAlt }
				size = { 'xs' }
				style = {{ marginLeft: 8 }}
			/>
		</Link>
	) }

	const renderAuthorImage = article => { return (
		<Image
			src = { getCloudinaryImagePath(article.authorImgSrcName) }
			alt = { article.altText }
			objectFit = 'aspect-fit'
			boxSize = { ['30px', '40px', '50px'] }
			borderRadius = '100px'
			borderWidth = '1px'
			borderStyle = 'solid'
			borderColor = { borderColor }
		/>
	) }

	const renderAuthorName = article => { return (
		<Text
			fontSize = { ['sm', 'md', 'lg', 'xl'] }
			ml = { [2, 2, 3, 3] }
			color = { textColor }
			fontWeight = 'semibold'
		>
			{ article.author }
		</Text>
	) }

	const renderArticles = () => {
		return (articles.map((article) =>
			<Flex
				key={article.title}
				direction = 'column'
				align = 'flex-start'
				mb = { [16, 16, 24, 32] }
			>
				{ renderBanner(article) }
				{ renderHeading(article) }
				{ renderDescription(article) }
				<Flex
					my = { 2 }
					align = 'center'
				>
					{ renderDate(article) }
					{ renderLink(article) }
				</Flex>
				<Flex
					direction = 'row'
					align = 'center'
					mt = { [2, 2, 3, 4] }
				>
					{ renderAuthorImage(article) }
					{ renderAuthorName(article) }
				</Flex>
			</Flex>
		))
	}

	return (
		<Flex
			direction = 'column'
			w = 'full'
			justify = 'flex-start'
			align = 'center'
			minH = '100vh'
		>
			<Flex
				direction = 'column'
				alignSelf = 'center'
				justifySelf = 'center'
				align = 'flex-start'
				justify = 'center'
				w = { ['96%', '90%', '86%', '80%']}
				maxW = { '800px' }
				m = { [16, 16, 24, 32] }
			>
				{ renderTitle() }
				{ renderArticles() }
			</Flex>
		</Flex>
	)
}

export default Media;