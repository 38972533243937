import React from 'react'
import { Flex, Heading, Image, Link, Text, useColorMode } from '@chakra-ui/react'
import { getCloudinaryImagePath } from './App'
import { values } from '../data/values'
import { advisors, team } from '../data/team'

export const About = () => {
	const { colorMode } = useColorMode()
	const textColor = colorMode === 'light' ? 'black' : 'white'
	const teamImgBorderColor = colorMode === 'light' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)'

	const renderVision = () => { return (
		<Flex
			direction = 'column'
			m = { [16, 16, 24, 32] }
		>
			<Text
				mb = { [4, 4, 6, 8] }
				fontSize = { ['md', 'lg', 'xl', '2xl'] }
				color = 'primary'
				textTransform = 'uppercase'
			>
				vision
			</Text>
			<Heading
				fontSize = { ['3xl', '4xl', '5xl', '6xl'] }
				color = { textColor }
			>
				A decentralized, circular economy fueled by local resources and human potential
			</Heading>
		</Flex>
	) }

	const renderValues = () => { return (
		<Flex
			direction = 'column'
			wrap = 'nowrap'
			m = { [16, 16, 24, 32] }
		>
			<Text
				mb = { [4, 4, 6, 8] }
				fontSize = { ['md', 'lg', 'xl', '2xl'] }
				color = 'primary'
				textTransform = 'uppercase'
			>
				values & culture codes
			</Text>

			<Flex
				direction = { ['column', 'column', 'row'] }
				wrap = { ['nowrap', 'nowrap', 'wrap'] }
			>
				{ values.map(value =>
					<Flex
						key = { value.title }
						direction = { ['column', 'column', 'row'] }
						align = 'flex-start'
						justify = 'flex-start'
						w = { ['full', 'full', 'full', 'full', '50%'] }
						my = { [4, 4, 6, 8] }
						pr = { [0, 0, 6, 8] }
					>
						<Image
							src = { getCloudinaryImagePath( colorMode === 'light' ? value.imgSrcName : value.darkImgSrcName ) }
							mr = { [4, 4, 6, 8] }
							// mt = { [1, 1, 2, 3] }
							mt = { 0 }
							boxSize = { ['100px', '160px'] }
							minW = { ['100px', '160px'] }
							// minW = '160px'
						/>
						<Flex
							direction = 'column'
						>
							<Heading
								fontSize = { ['lg', 'xl', '2xl', '3xl'] }
								color = { textColor }
								mb = { [1, 1, 2, 2] }
							>
								{ value.title }
							</Heading>
							<Text
								fontSize = { ['sm', 'md', 'lg', 'xl'] }
								color = { textColor }
							>
								{ value.description }
							</Text>
						</Flex>
					</Flex>
				) }
			</Flex>
		</Flex>
	) }

	const renderTeamMember = member => { return (
		<Flex
			key = { member.name }
			as = { Link }
			direction = 'row'
			align = 'center'
			pr = { [4, 4, 6, 8] }
			mb = { [4, 4, 6, 8] }
			w = { ['full', 'full', '50%', '50%', '33%'] }
			href = { member.href }
			isExternal
			_hover = {{
				textDecoration: 'none',
				opacity: '0.67'
			}}
		>
			<Image
				boxSize = '100px'
				minW = '100px'
				mr = { [4, 4, 6, 8] }
				borderRadius = '100px'
				borderWidth = '1px'
				borderStyle = 'solid'
				borderColor = { teamImgBorderColor }
				src = { getCloudinaryImagePath(member.pngSrcName) }
				alt = { member.altText }
			/>
			<Flex
				direction = 'column'
			>
				<Text
					fontSize = { ['sm', 'md', 'lg', 'xl'] }
					fontWeight = 'bold'
					color = { textColor }
				>
					{ member.name }
				</Text>
				<Text
					fontSize = { ['xs', 'sm', 'md', 'lg'] }
					color = { textColor }
				>
					{ member.title }
				</Text>
			</Flex>
		</Flex>
	) }

	const renderTeamSection = section => { return (
		<Flex
			direction = 'column'
			key = { section }
		>
			<Text
				mt = { [2, 2, 3, 4] }
				fontSize = { ['xs', 'sm', 'md', 'lg'] }
				color = { textColor }
				textTransform = 'uppercase'
				opacity = '0.5'
				fontStyle = 'italic'
			>
				{ section }
			</Text>
			<Flex
				direction = 'row'
				wrap = 'wrap'
				my = { [4, 4, 6, 8] }
			>
				{ team[section].map(member =>
					renderTeamMember(member)
				) }
			</Flex>
		</Flex>
	) }

	const renderTeam = () => { return (
		<Flex
			direction = 'column'
			m = { [16, 16, 24, 32] }
		>
			<Text
				mt = { [4, 4, 6, 8] }
				fontSize = { ['md', 'lg', 'xl', '2xl'] }
				color = 'primary'
				textTransform = 'uppercase'
			>
				team
			</Text>
			{ Object.keys(team).map(key =>
				renderTeamSection(key)
			) }
		</Flex>
	) }

	const renderAdvisors = () => { return (
		<Flex
			direction = 'column'
			m = { [16, 16, 24, 32] }
		>
			<Text
				mt = { [4, 4, 6, 8] }
				fontSize = { ['md', 'lg', 'xl', '2xl'] }
				color = 'primary'
				textTransform = 'uppercase'
			>
				Advisors
			</Text>
			<Flex
				direction = 'column'
				key = { 'advisors' }
			>
				<Flex
					direction = 'row'
					wrap = 'wrap'
					my = { [4, 4, 6, 8] }
				>
					{ advisors.map(member =>
						renderTeamMember(member)
					) }
				</Flex>
			</Flex>
		</Flex>
	) }

	return (
		<Flex
			direction = 'column'
			minH = '100vh'
		>
			{ renderVision() }
			{ renderValues() }
			{ renderTeam() }
			{ renderAdvisors() }
		</Flex>
	)
}

export default About;