import React from 'react'
import { Button, Flex, Heading, Image, Link, Menu, MenuButton, MenuItem, MenuList, Text, useColorMode } from "@chakra-ui/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";

const getCloudinaryImagePath = (fileName) =>
  "https://res.cloudinary.com/resource-network/image/upload/fl_attachment/v1639786610/resource-protocol/" +
  fileName

// source token
const sourcePrimary = {
  id: 'sourcePrimary',
  imgSrcName: 'brand/SOURCE_lhkhsm.svg',
  backgroundMode: 'darkBg',
  imagePaths: {
    '32': 'brand/SOURCE_32_fl52qd.png',
    '64': 'brand/SOURCE_64_bmybpc.png',
    '96': 'brand/SOURCE_96_kxl9ma.png',
    '128': 'brand/SOURCE_128_frnzbu.png',
    '150': 'brand/SOURCE_150_inwlvl.png',
    '200': 'brand/SOURCE_200_ire2bj.png',
    '256': 'brand/SOURCE_256_jeqesh.png',
    '512': 'brand/SOURCE_512_uzjlez.png',
  }
}
const sourceInverse = {
  id: 'sourceInverse',
  imgSrcName: 'brand/SOURCE-inverse_lwspib.svg',
  backgroundMode: 'lightBg',
  imagePaths: {
    '32': 'brand/SOURCE-inverse_32_q3ruk4.png',
    '64': 'brand/SOURCE-inverse_64_uybmtd.png',
    '96': 'brand/SOURCE-inverse_96_jlstwh.png',
    '128': 'brand/SOURCE-inverse_128_fq2cse.png',
    '150': 'brand/SOURCE-inverse_150_evsdng.png',
    '200': 'brand/SOURCE-inverse_200_gkt6ir.png',
    '256': 'brand/SOURCE-inverse_256_u34umi.png',
    '512': 'brand/SOURCE-inverse_512_vcgsp5.png',
  }
}
// logotype
const logotypePrimary = {
  id: 'logotypePrimary',
  imgSrcName: 'brand/resource-logotype_iaurum.svg',
  backgroundMode: 'lightBg',
  imagePaths: {
    '128': 'brand/resource-logotype_128_umruua.png',
    '150': 'brand/resource-logotype_150_xktbzq.png',
    '200': 'brand/resource-logotype_200_wsq81r.png',
    '256': 'brand/resource-logotype_256_upzsyt.png',
    '512': 'brand/resource-logotype_512_hhsq07.png',
    '1024': 'brand/resource-logotype_1024_rnsb34.png',
  }
}
const logotypeWhite = {
  id: 'logotypeWhite',
  imgSrcName: 'brand/resource-logotype-white_osat1c.svg',
  backgroundMode: 'darkBg',
  imagePaths: {
    '128': 'brand/resource-logotype-white_128_jzplha.png',
    '150': 'brand/resource-logotype-white_150_rulpjw.png',
    '200': 'brand/resource-logotype-white_200_xo9bmi.png',
    '256': 'brand/resource-logotype-white_256_pwjsrj.png',
    '512': 'brand/resource-logotype-white_512_kwphjl.png',
    '1024': 'brand/resource-logotype-white_1024_sz4e8l.png',
  }
}
const logotypeBlack = {
  id: 'logotypeBlack',
  imgSrcName: 'brand/resource-logotype-black_lsczap.svg',
  backgroundMode: 'lightBg',
  imagePaths: {
    '128': 'brand/resource-logotype-black_128_e7l4s0.png',
    '150': 'brand/resource-logotype-black_150_dquhrs.png',
    '200': 'brand/resource-logotype-black_200_qt97oi.png',
    '256': 'brand/resource-logotype-black_256_taaf4t.png',
    '512': 'brand/resource-logotype-black_512_jhlcsy.png',
    '1024': 'brand/resource-logotype-black_1024_kmef6c.png',
  }
}
// logo
const logoPrimary = {
  id: 'logoPrimary',
  imgSrcName: 'brand/logo_pfzn2n.svg',
  backgroundMode: 'darkBg',
  imagePaths: {
    '32': 'brand/logo_32_lkh9r5.png',
    '48': 'brand/logo_48_fuqbme.png',
    '64': 'brand/logo_64_swsfrb.png',
    '96': 'brand/logo_96_zlhqek.png',
    '128': 'brand/logo_128_mythe8.png',
    '150': 'brand/logo_150_rcaoec.png',
    '200': 'brand/logo_200_uzvera.png',
    '256': 'brand/logo_256_ffyhem.png',
    '512': 'brand/logo_512_oedswd.png',
  }
}
const logoInverse = {
  id: 'logoInverse',
  imgSrcName: 'brand/logo-inverted_svuu2n.svg',
  backgroundMode: 'lightBg',
  imagePaths: {
    '32': 'brand/logo-inverted_32_g455sv.png',
    '48': 'brand/logo-inverted_48_g2netb.png',
    '64': 'brand/logo-inverted_64_zw6ekg.png',
    '96': 'brand/logo-inverted_96_jf4wfp.png',
    '128': 'brand/logo-inverted_128_gsx13a.png',
    '150': 'brand/logo-inverted_150_jcrsg4.png',
    '200': 'brand/logo-inverted_200_vdnhpi.png',
    '256': 'brand/logo-inverted_256_bpnsn3.png',
    '512': 'brand/logo-inverted_512_jnabb8.png',
  }
}
const logoBlack = {
  id: 'logoBlack',
  imgSrcName: 'brand/logo-black_tp3gni.svg',
  backgroundMode: 'lightBg',
  imagePaths: {
    '32': 'brand/logo-black_32_ziykye.png',
    '48': 'brand/logo-black_48_h5okqh.png',
    '64': 'brand/logo-black_64_alrfwc.png',
    '96': 'brand/logo-black_96_onmfxd.png',
    '128': 'brand/logo-black_128_lgnzko.png',
    '150': 'brand/logo-black_150_klo9l8.png',
    '200': 'brand/logo-black_200_xamogd.png',
    '256': 'brand/logo-black_256_h0r60s.png',
    '512': 'brand/logo-black_512_axfkp1.png',
  }
}
const logoWhite = {
  id: 'logoWhite',
  imgSrcName: 'brand/logo-white_mceufw.svg',
  backgroundMode: 'darkBg',
  imagePaths: {
    '32': 'brand/logo-white_32_cjm6np.png',
    '48': 'brand/logo-white_48_cruea4.png',
    '64': 'brand/logo-white_64_im5qvh.png',
    '96': 'brand/logo-white_96_imyz99.png',
    '128': 'brand/logo-white_128_q1vdmt.png',
    '150': 'brand/logo-white_150_hgs434.png',
    '200': 'brand/logo-white_200_fjrsmw.png',
    '256': 'brand/logo-white_256_rnknrt.png',
    '512': 'brand/logo-white_512_ocgazp.png',
  }
}

// data
const variations = {
  'Logotype': [ logotypePrimary , logotypeWhite , logotypeBlack ],
  'SOURCE Token': [ sourcePrimary , sourceInverse ],
  'Logo': [ logoPrimary , logoInverse , logoWhite , logoBlack ]
}

export const Brand = () => {
  const { colorMode } = useColorMode()
  const textColor = colorMode === 'light' ? 'black' : 'white'

  const renderHeader = () => {
    return (
      <Flex
        direction = 'column'
        justify = 'center'
        align = 'flex-start'
        w = 'full'
        maxW = '1600px'
        mt = { [16, 16, 24, 32] }
      >
        <Heading
          color = { textColor }
          fontSize = { ['lg', 'xl', '2xl', '3xl'] }
        >
          ReSource Brand Assets
        </Heading>
        <Text
          color = { textColor }
          fontSize = { ['sm', 'md', 'lg', 'xl'] }
          mt = { 2 }
        >
          Please do not edit, change, distort, recolor, or reconfigure the ReSource logos.
        </Text>
      </Flex>
    )
  }


  const renderVariations = (imageCategory) => {
    return( variations[ imageCategory ].map( variation =>
      <Flex
        key = { variation.id }
        direction = 'column'
        className = 'variation'
        align = 'flex-start'
        flexGrow = '1'
        mr = '2vw'
        mb = { [8, 8, 12, 16] }
      >
        <Image
          minH = '64px'
          minW = '64px'
          maxH = '128px'
          borderRadius = { 8 }
          border = '1px solid #BDBDBD'
          padding = { [4, 4, 6, 8] }
          src = { getCloudinaryImagePath(variation.imgSrcName) }
          alt = { `resource ${imageCategory}` }
          className = { variation.backgroundMode }
        />
        <Flex
          className={"downloadButtons"}
          direction = 'row'
          align = 'flex-start'
          justify = 'center'
          mt = { 4 }
        >
          { renderSvgButton(variation) }
          { renderPngMenu(variation) }
        </Flex>
      </Flex>
    ))
  }

  const renderPngMenu = variation => { return (
    <Menu>
      <MenuButton
        as = { Button }
        rightIcon = { <FontAwesomeIcon icon = { faChevronDown } /> }
        _hover = {{ boxShadow: 'none' }}
        _focus = {{ boxShadow: 'none' }}
        _active = {{ boxShadow: 'none' }}
      >
        .PNG
      </MenuButton>
      <MenuList
        boxShadow = 'none'
        _hover = {{ boxShadow: 'none' }}
        _focus = {{ boxShadow: 'none' }}
        _active = {{ boxShadow: 'none' }}
      >
        { Object.keys(variation.imagePaths).map( (key) =>
          <MenuItem
            key = { key }
            as = { Link }
            href = { getCloudinaryImagePath(variation.imagePaths[key]) }
            download
            rel={"noreferrer nofollow"}
            _hover = {{
              textDecoration: 'none',
              boxShadow: 'none',
              bgColor: 'rgba(0, 0, 0, 0.1)'
            }}
            _active = {{
              boxShadow: 'none !important',
              bgColor: 'rgba(0, 0, 0, 0.1)'
            }}
            _focus = {{
              boxShadow: 'none !important',
              bgColor: 'rgba(0, 0, 0, 0.1)'
            }}
          >
            { key + 'px' }
          </MenuItem>
        ) }
      </MenuList>
    </Menu>
  ) }

  const renderSvgButton = variation => {
    return (
      <Button
        href = { getCloudinaryImagePath(variation.imgSrcName) }
        download
        as = { Link }
        rel = "nofollow noreferrer"
        className={ 'svg-button' }
        borderWidth = { '1px' }
        borderColor = { textColor }
        bgColor = { 'none' }
        color = { textColor }
        cursor = { 'pointer' }
        mr = { 4 }
        textDecoration = 'none'
        _focus = {{
          borderColor: 'primaryActive',
          color: 'primaryActive',
          textDecoration: 'none'
        }}
        _active = {{
          borderColor: 'primaryActive',
          color: 'primaryActive',
          textDecoration: 'none'
        }}
        _hover = {{
          borderColor: 'primaryActive',
          color: 'primaryActive',
          textDecoration: 'none'
        }}
      >
        .SVG
      </Button>
    )
  }

  const renderBrandImages = (imageCategory) => {
    return (
      <Flex
        direction = 'column'
        w = 'full'
        my = { [8, 8, 12, 16] }
      >
        <Heading
          mb = { 6 }
          color = { textColor }
          fontSize = { ['lg', 'xl', '2xl', '3xl'] }
        >
          {imageCategory}
        </Heading>
        <Flex
          direction = 'row'
          wrap = { ['wrap', 'wrap', 'nowrap'] }
          align = ''
        >
          { renderVariations(imageCategory) }
        </Flex>
      </Flex>
    )
  }

  return (
    <div>
      <Flex
        direction = 'center'
        align = 'center'
        justify = 'center'
        mb = { 16 }
      >
        <Flex
          direction = 'column'
          align = 'center'
          justify = 'center'
          px = { [8, 8, 16, 32] }
          maxW = '1600px'
          w = '100%'
        >
          { renderHeader() }
          { renderBrandImages('Logotype') }
          { renderBrandImages('Logo') }
          { renderBrandImages('SOURCE Token') }
        </Flex>
      </Flex>
    </div>
  )
}

export default Brand;